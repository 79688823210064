<template>
  <div>
    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <Card class="w-10 h-10 flex ml-auto mr-3 -mt-6 mb-6">
        <div v-if="isDownloading"  class="w-full h-full p-1 pb-7 flex justify-center items-center">
          <loader size="xxxs" :loader-image="false" loading-bar-size="width:40px !important" />
        </div>
        <div v-if="!isDownloading" @click="triggerDownload" class="w-full h-full flex justify-center items-center">
          <Icon
            class-name="text-darkPurple cursor-pointer mx-auto self-center cursor-pointer"
            size="s"
            icon-name="black-download-icon"
          />
        </div>
      </Card>
      <div class="w-auto h-auto px-3" ref="printSection">
        <card class="p-5 w-full h-auto">
          <div class="w-auto h-auto flex flex-col">
            <div class="flex mb-4">
              <p class="font-bold text-xl text-darkPurple">
                {{
                  $DATEFORMAT(
                    new Date(
                      payslipDetails.year +
                        "-" +
                        "0" +
                        payslipDetails.month +
                        "-" +
                        1
                    ),
                    "LLLL yyyy"
                  )
                }}
                Payslip
              </p>
            </div>
            <div class="w-full">
              <div class="w-full -mt-4 flex justify-between items-center">
                <div class="flex flex-col flex-grow">
                  <img
                    v-if="
                      organizationInfo.logoUrl !== null &&
                        organizationInfo.logoUrl !== ''
                    "
                    class="mr-3 rounded"
                    style="width: 102px; height: 102px"
                    :src="organizationInfo.logoUrl"
                    alt="profile"
                  />
                  <div
                    style="height: 102px; width: 102px; border-radius: 5px; direction: ltr"
                    class="text-blueCrayola border text-center font-semibold pt-9 mr-2"
                    v-else
                  >
                    {{ organizationInfo.name }}
                  </div>
                </div>
                <div>
                  <div class="flex flex-col gap-2 text-base">
                    <p class="text-lg font-extrabold" style="direction: ltr">
                      {{ organizationInfo.name }}
                    </p>
                    <p style="direction: ltr">
                      {{ organizationLocation.address }}
                    </p>
                    <p style="direction: ltr">
                      {{ organizationInfo.email }}
                    </p>
                    <p style="direction: ltr">
                      {{ organizationInfo.phoneNo }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="w-full border border-dashed my-2"
                style="border-width: 0.5px"
              />

              <div class="px-2">
                <p
                  class="text-lg pb-1 font-extrabold"
                  style="border-bottom: 1px solid #F15A29; width:fit-content"
                >
                  Employee Summary
                </p>
              </div>

              <div
                class="w-full flex justify-between"
              >
                <div class="flex flex-col justify-start gap-1 p-2 w-1/2">
                  <div class="w-full flex justify-between text-sm">
                    <span>
                      Employee ID:
                    </span>
                    <span class="w-1/2 inline-block">
                      {{
                        employee.employeeId === null
                          ? "---"
                          : employee.employeeId
                      }}
                    </span>
                  </div>
                  <div class="w-full flex justify-between text-sm">
                    <span>
                      Name:
                    </span>
                    <span class="w-1/2 inline-block">
                      {{
                        `${employee.fname} ${
                          employee.mname === null ? "" : employee.mname
                        } ${employee.lname}`
                      }}
                    </span>
                  </div>
                  <div class="w-full flex justify-between text-sm">
                    <span>
                      Designation:
                    </span>
                    <span class="w-1/2 inline-block">
                      {{
                        payslipDetails.userDesignation === null
                          ? "--"
                          : payslipDetails.userDesignation.name
                      }}
                    </span>
                  </div>
                  <div class="w-full flex justify-between text-sm">
                    <span>
                      Employment Date:
                    </span>
                    <span class="w-1/2 inline-block">
                      {{ convertDate(employee.employmentDate) }}
                    </span>
                  </div>
                </div>

                <div
                  class="flex flex-col justify-start gap-1 py-2 w-1/2 ml-6"
                >
                  <div class="w-full flex justify-start text-sm">
                    <span class="w-2/3 inline-block">
                      Pay Frequency:
                    </span>
                    <span>
                      {{
                        payrunDetails.payFrequency
                          ? handlePayFrequency(payrunDetails.payFrequency)
                          : "-"
                      }}
                    </span>
                  </div>
                  <div class="w-full flex justify-start text-sm">
                    <span class="w-2/3 inline-block">
                      Pay Period:
                    </span>
                    <span>
                      {{
                        `${
                          payrunDetails.payDate
                            ? `${$DATEFORMAT(
                                new Date(
                                  payrunDetails.month + "-" + 1
                                ),
                                "LLLL"
                              )} ${payrunDetails.payStartDay} - ${
                                payrunDetails.payEndDay
                              }`
                            : "-"
                        }`
                      }}
                    </span>
                  </div>
                  <div class="w-full flex justify-start text-sm">
                    <span class="w-2/3 inline-block">
                      Tax Identity Number (TIN):
                    </span>
                    <span>
                      {{
                        employee.employeeTaxId ? employee.employeeTaxId : "-"
                      }}
                    </span>
                  </div>
                  <div class="w-full flex justify-start text-sm">
                    <span class="w-2/3 inline-block">
                      Pension Identity Number (PIN):
                    </span>
                    <span>
                      {{ employee.rsaId ? employee.rsaId : "-" }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="w-full flex justify-between items-center gap-6 py-4 px-3" style="background:#321C3B">
                <div class="flex justify-start items-center text-base font-bold text-white w-1/2">
                  <p>
                    Employee Net Pay: {{ convertToCurrency(payslipDetails.netPay) }}
                  </p>
                </div>
                <div class="flex justify-between items-center gap-6 pr-3 text-base font-bold text-white w-1/2">
                  <p>
                    Pay Date:
                  </p>
                  <p>
                    {{
                      payrunDetails.payDate
                        ? $DATEFORMAT(
                            new Date(payrunDetails.payDate),
                            "dd MMMM, yyyy"
                          )
                        : "-"
                    }}
                  </p>
                </div>
              </div>

              <div class="w-full flex mt-2">
                <card
                  class="w-1/2 flex flex-col p-2"
                  style="border: 1px solid #878e99"
                >
                  <div class="flex flex-col w-full py-1">
                    <p class="text-base font-extrabold">
                      Earnings (Current Month)
                    </p>
                  </div>
                  <div class="w-full mb-2" style="border: 1px solid #878e99;" />

                  <div
                    class="w-full"
                    v-for="(item, index) in earnings"
                    :key="index"
                  >
                    <div class="w-full flex justify-between items-center text-sm mb-1">
                      <span>
                        {{ item.name }}
                      </span>
                      <span>
                        {{ convertToCurrency(item.amountPerMonth) }}
                      </span>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-auto text-lg font-semibold">
                    <span>
                      Total Earnings
                    </span>
                    <span>
                      {{ convertToCurrency(totalEarnings) }}
                    </span>
                  </div>
                </card>

                <card
                  class="w-1/2 flex flex-col justify-between p-2 ml-6"
                  style="border: 1px solid #878e99;"
                >
                  <div class="flex flex-col w-full py-1">
                    <p class="text-base font-extrabold">
                      Deductions (Current Month)
                    </p>
                  </div>

                  <div class="w-full mb-2" style="border: 1px solid #878e99;" />
                  <div
                    class="w-full"
                    v-for="(item, index) in deductions"
                    :key="index"
                  >
                    <div class="w-full flex justify-between items-center text-sm mb-1">
                      <span>
                        {{ item.name }}
                      </span>
                      <span>
                        {{ convertToCurrency(item.amount) }}
                      </span>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mt-auto text-lg font-semibold">
                    <span>
                      Total Deductions
                    </span>
                    <span>
                      {{ convertToCurrency(totalDeductions) }}
                    </span>
                  </div>
                </card>
              </div>

              <div class="w-full flex mt-2">
                <card
                  class="w-1/2 flex flex-col p-2"
                  style="border: 1px solid #878e99;"
                >
                  <div class="flex flex-col w-full py-1">
                    <p class="text-base font-extrabold">
                      Others (Current Month)
                    </p>
                  </div>

                  <div
                    class="w-full bordered mb-1"
                    style="border: 1px solid #878e99;"
                  />
                  <div
                    class="w-full"
                    v-for="(item, index) in additions"
                    :key="index"
                  >
                    <div class=" w-full flex justify-between items-center text-sm mb-1">
                      <span>
                        {{ item.name }}
                      </span>
                      <span>
                        {{ convertToCurrency(item.amount) }}
                      </span>
                    </div>
                  </div>

                  <div class="w-full flex justify-between items-center mb-2 mt-auto">
                    <span class="flex flex-grow text-lg font-semibold">
                      Total
                    </span>
                    <span class="flex text-lg font-semibold">
                      {{ convertToCurrency(totalAdditions) }}
                    </span>
                  </div>
                </card>

                <card
                  class="w-1/2 flex flex-col p-1 ml-6"
                  style="border: 1px solid #878e99;"
                >
                  <div class="flex flex-col w-full py-1">
                    <p class="text-base font-extrabold">
                      Summary
                    </p>
                  </div>

                  <div
                    class="w-full bordered mb-2"
                    style="border: 1px solid #878e99;"
                  />
                  <div
                    class="w-full"
                    v-for="(item, index) in summary"
                    :key="index"
                  >
                    <div class="w-full flex justify-between items-center text-sm mb-1">
                      <span class="">
                        {{ item.name }}
                      </span>
                      <span class="">
                        {{ convertToCurrency(item.amount) }}
                      </span>
                    </div>
                  </div>
                </card>
              </div>

              <div class="w-full mt-3 flex justify-between items-center text-xs text-jet">
                <p class="w-1/2">
                  This is a system generated Payslip for ({{
                    payslipDetails.fname + " " + payslipDetails.lname
                  }}).
                </p>

                <div class="w-1/2 flex justify-between items-center ml-6 gap-6">
                  <p>
                    Document ID: {{ payslipDetails.documentId }}
                  </p>
                  <p>
                    {{
                      $DATEFORMAT(
                        new Date(
                          payslipDetails.year +
                            "-" +
                            "0" +
                            payslipDetails.month +
                            "-" +
                            getToday()
                        ),
                        "dd LLLL yyyy"
                      )
                    }}
                  </p>
                </div>
              </div>

              <div class="w-full mx-auto mt-2 flex justify-center items-center gap-1 text-sm">
                <p>
                  Powered by
                </p>
                <img
                  class="h-6"
                  src="@/assets/images/cloudenly-logo.png"
                  alt="profile"
                />
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Card from "@/components/Card";

export default {
  components: {
    Card
  },
  data() {
    return {
      loading: true,
      printMen: this.$refs,
      organizationInfo: [],
      organizationLocation: [],
      employee: [],
      payslipDetails: [],
      earnings: [],
      deductions: [],
      reimbursements: [],
      totalEarnings: "",
      totalDeductions: "",
      totalReimbursement: "",
      totalAdditions: 0,
      additions: [],
      summary: [],
      payrunDetails: [],

      downloadEndpoint: process.env.VUE_APP_DOWNLOAD_PAYSLIP,
      isDownloading: false
    };
  },
  methods: {
    viewPayslip() {
      let earningSum = 0;
      let deductionSum = 0;
      let additionSum = 0;

      this.$_getPaySlipDetails(this.$route.params.id).then(result => {
        this.employee = result.data.payslip.employee;
        this.payslipDetails = result.data.payslip;
        this.payrunDetails = result.data.payslip.payrun;
        this.summary = result.data.payslip.summary;
        this.earnings = result.data.payslip.monthlyRegularPayItems;
        this.deductions = result.data.payslip.monthlyDeductionsPayItems;
        this.additions = result.data.payslip.monthlyAdditionsPayItems;

        this.earnings.map(item => {
          earningSum += item.amountPerMonth;
          return {};
        });

        this.deductions.map(item => {
          deductionSum += item.amount;
          return {};
        });

        this.additions.map(item => {
          additionSum += item.amount;
          return {};
        });

        this.totalEarnings = earningSum;
        this.totalDeductions = deductionSum;
        this.totalAdditions = additionSum;
        this.totalReimbursement = result.data.payslip.reimbursements;

        this.loading = false;
      });

      this.$_getOrgProfile().then(result => {
        this.organizationInfo = result.data.org;
      });
      this.$_getDefaultOfficeLocation().then(result => {
        this.organizationLocation = result.data.office;
      });
    },

    getMonthName(monthNumber){
      const months = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
      ];
      
      return months[monthNumber - 1];
    },

    async triggerDownload() {
      const htmlContent = this.$refs.printSection.innerHTML;
      const monthName = this.getMonthName(this.payslipDetails.month)
      const styles = `
        <link href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css" rel="stylesheet">
      `;
      // Constructed a complete HTML document
      const completeHtml = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>${monthName} Payslip</title>
              ${styles}
          </head>
          <body>
              ${htmlContent}
          </body>
          </html>
      `;
      const orgId = this.$orgId;
      const payslipName = `${this.$DATEFORMAT(
              new Date(this.payslipDetails.year + "-" + "0" + this.payslipDetails.month + "-" + 1),
                "LLLL yyyy")} Payslip`
      const payload = {body: completeHtml, orgId, payslipName}
                  
       this.isDownloading = true;
      try {
        const response = await axios.post(this.downloadEndpoint, payload, {
          responseType: 'arraybuffer'
        });
        
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${monthName}-payslip.pdf`;
        // Appended the link to the body (necessary for Firefox)
        document.body.appendChild(link);
        link.click();

        // Removed the link after download
        document.body.removeChild(link);
        this.isDownloading = false;
      } catch (error) {
         this.isDownloading = false;
          return this.$toasted.error(`Error downloading the payslip`,{duration: 6000});
      }
    },

    getToday() {
      return String(new Date().getDate()).padStart(2, "0");
    },

    convertDate(setdate) {
      const setDate = new Date(setdate);
      setDate.setHours(setDate.getHours() + 8);
      return this.$DATEFORMAT(new Date(setDate), "dd, MMMM yyyy");
    },

    convertCreatedDate(dateCreated) {
      return this.$DATEFORMAT(new Date(dateCreated), "do MMMM, yyyy");
    },
  },
  mounted() {
    this.viewPayslip();
  }
};
</script>
<style scoped>
.bordered {
  border: 1px solid #878e99;
}
</style>